var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    [
      _c("dynamic-form", {
        attrs: { isReadOnly: _vm.isReadOnly },
        on: { "check-validation": _vm.checkValidation },
        model: {
          value: _vm.form.form,
          callback: function($$v) {
            _vm.$set(_vm.form, "form", $$v)
          },
          expression: "form.form"
        }
      }),
      !_vm.isReadOnly
        ? _c(
            "div",
            { staticClass: "py-8" },
            [
              _c(
                "vs-button",
                {
                  staticClass: "rounded-lg mr-3",
                  attrs: { type: "border" },
                  on: {
                    click: function($event) {
                      return _vm.$emit("reset-data")
                    }
                  }
                },
                [_vm._v(_vm._s(_vm.$t("apps.configurator.home-layout.cancel")))]
              ),
              _c(
                "vs-button",
                {
                  attrs: { disabled: _vm.invalidForm },
                  on: { click: _vm.updateData }
                },
                [_vm._v(_vm._s(_vm.$t("apps.configurator.home-layout.save")))]
              )
            ],
            1
          )
        : _vm._e()
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }