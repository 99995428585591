<template>
    <div class="advice-container px-4 py-3 rounded relative text-primary flex">
        <feather-icon icon="InfoIcon" svgClasses="h-5 w-5" class="pr-5"></feather-icon>
        <span class="block sm:inline">{{ message }}</span>
    </div>
</template>

<script>
export default {
    name: 'empty-alert',
    components: {},
    props: {
        message: String,
    }
}
</script>