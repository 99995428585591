import axios from '@/axios.js'

export default {
    getStyleList: async (versionUuid) => {
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/styles`, { params: {q: `version_uuid:${versionUuid}` }})
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getTemplateList: async () => { 
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/styles-templates`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    getValuesList: async (style) => { 
        try {
            const response = await axios.editorapi.get(`/v4/app-configurator/styles/${style}`)
            return response.data.object
        } catch (error) {
            throw new Error(error)
        }
    },
    editValuesList: async (style) => { 
        let body = await {
            values: JSON.stringify(style.form.values),
            timeofyear: style.timeOfYear
        }
        try {
            const response = await axios.editorapi.patch(`/v4/app-configurator/styles/${style.uuid}`, body)
            return response.data
        } catch (error) {
            throw new Error(error)
        }
    },
    addNewStyle: async(style, route) => {
        let body = await {
            values: JSON.stringify(style.form.values),
            timeofyear: style.timeOfYear,
            version_uuid: route
        }
        try {
            const response = await axios.editorapi.post(`/v4/app-configurator/styles/${style.uuid}`,body)
            return response.data
        } catch(error) {
            throw new Error(error)
        }
    },
    deleteStyle: async(style) => {
        try {
            const response = await axios.editorapi.delete(`/v4/app-configurator/styles/${style.uuid}`)
            return response.data
        } catch(error) {
            throw new Error(error)
        }
    }
}
