var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "div",
    {
      staticClass:
        "advice-container px-4 py-3 rounded relative text-primary flex"
    },
    [
      _c("feather-icon", {
        staticClass: "pr-5",
        attrs: { icon: "InfoIcon", svgClasses: "h-5 w-5" }
      }),
      _c("span", { staticClass: "block sm:inline" }, [
        _vm._v(_vm._s(_vm.message))
      ])
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }