<template>
    <DatatablePageLayout>
        <template v-slot:actionButtons>
            <vs-button
                v-if="missingStyles() && !isReadOnly"
                class="rounded-lg xs:w-full sm:w-auto"
                @click="createStyle()"
            >
                {{$t('apps.configurator.styles.new-style-button')}}
            </vs-button>
        </template>
        <template v-slot:datatable>
            <div v-if="styles.length === 0 && !createNewStyle" class="w-full vx-col mt-5">
                <emptyAlert :message="$t('apps.configurator.alert-empty.styles-text')"/>
            </div>
            <div v-if="styles.length > 0 || createNewStyle" class="w-full vx-col bg-white p-8">
                <vs-collapse v-if="styles.length > 0 || createNewStyle">
                    <vs-collapse-item  v-if="createNewStyle" open key="new" >
                        <div slot="header" class="flex justify-between items-center">
                            <span class="pl-5 text-lg font-medium">{{$t('apps.configurator.styles.new-style')}}</span>
                            <vs-button v-if="!isReadOnly" type="border" color="danger" class="rounded-lg px-3 py-2 mr-5 text-danger" @click="removeNewStyle">
                                <feather-icon icon="TrashIcon" svgClasses="align-middle w-6" />
                            </vs-button>
                        </div>
                        <div class="px-8">
                            <label-form name="Select_mode" >{{$t('apps.configurator.styles.select-mode')}} *</label-form>
                            <vs-select class="w-full md:w-1/3" v-model="timeOfYear" v-on:change="setTimeOfYear">
                                <vs-select-item v-for="time in timeOfYearOps" :value="time.value"  :key="time.value" :text="time.label" />
                            </vs-select>
                            <div class="mt-5">
                                <validaton-dynamic-form :isReadOnly="isReadOnly" :form="newStyle" @update-data="addStyle"/>
                            </div>
                        </div>
                    </vs-collapse-item>
                    <vs-collapse-item v-for="style in styles" :key="style.timeOfYear">
                        <div slot="header" class="flex justify-between items-center">
                            <span class="pl-5 text-lg font-medium">{{style.timeOfYear}}</span>
                            <vs-button v-if="!isReadOnly" type="border" color="danger" class="rounded-lg px-3 py-2 mr-5 text-danger" @click="(e) => showDeleteConfirm(e, style)">
                                <feather-icon icon="TrashIcon" svgClasses="align-middle w-6" />
                            </vs-button>
                        </div>
                        <div class="px-8">
                            <validaton-dynamic-form :isReadOnly="isReadOnly" :form="style" @update-data="editData" @reset-data="resetData(style)"/>
                        </div>
                    </vs-collapse-item>
                </vs-collapse>
            </div>
        </template>
    </DatatablePageLayout>
</template>

<script>
import LabelForm from "@/modules/Shared/Components/form/LabelForm.vue";
import ConfiguratorStylesService from '@/modules/Apps/Services/Configurator/ConfiguratorStylesService'
import ValidatonDynamicForm from '../../../Shared/Components/form/ValidationDynamicForm.vue'
import loader from "@/modules/Shared/Mixins/loader.js";
import emptyAlert from '@/modules/Shared/Components/alert/emptyAlert'

import { v4 as uuidv4 } from 'uuid';
import ConfiguratorVersionService from "@/modules/Apps/Services/Configurator/ConfiguratorVersionService";
import store from "@/modules/Shared/Store/store";
import DatatablePageLayout from "@/modules/Shared/Pages/DatatablePageLayout";

export default {
    components: {
        LabelForm,
        ValidatonDynamicForm,
        DatatablePageLayout,
        emptyAlert
    },
    name: 'Style-Menu',
    mixins: [loader],
    computed: {
        isReadOnly() {
            return this.$store.state.auth.drm['admin'].length === 0
        }
    },
    data() {
        return {
            styles: [],
            newStyle: {},
            createNewStyle: false,
            missingTimes: [],
            template: [],
            timeOfYear: 'winter',
            timeOfYearOps: [
                { label: this.$t('apps.configurator.styles.timeofyear-winter'), value: "winter" },
                { label: this.$t('apps.configurator.styles.timeofyear-summer'), value: "summer" },
            ]
        }
    },
    async beforeRouteEnter (to, from, next) {
        const version = await ConfiguratorVersionService.getVersion(to.params.versionUuid);
        await store.dispatch('setRouteElement', version)
        next()
    },
    async created() {
        await this.initData()
    },
    methods: {
        async initData() {
            await this.load(async () => {await this.prepareInformationData();});
            this.newStyle = {
                uuid: '',
                timeOfYear: '',
                form: {
                    template: this.template,
                    values: []
                }
            }
            this.styles.forEach(style => {style.form.template.forEach(template => {
                if(style.form.values[template.key] == undefined) style.form.values = {...style.form.values, [template.key]: ''};
            });});
            this.newStyle.form.template.forEach(template => {
                this.newStyle.form.values = {...this.newStyle.form.values, [template.key]: ''};
            });
            if(this.styles.length === 0) this.missingTimes = ["winter", "summer"];
            else if (this.styles[0].timeOfYear === "winter") this.missingTimes = ["summer"];
            else if (this.styles[0].timeOfYear === "summer") this.missingTimes = ["winter"];
        },
        setTimeOfYear(timeofyear) {
            this.timeOfYear = timeofyear
        },
        async prepareInformationData() {
            let responseStyle = await this.prepareStyleData();
            let responseTemplate = await this.prepareTemplateData();
            this.template = responseTemplate;
            this.styles = await Promise.all(responseStyle.map(async function(style){
                let responseValues = await ConfiguratorStylesService.getValuesList(style.uuid);
                return {
                    uuid: style.uuid,
                    timeOfYear: style.timeOfYear,
                    form: {
                        template: responseTemplate,
                        values: responseValues.values
                    }
                };
            }));
        },
        async prepareStyleData() {
            let response = await ConfiguratorStylesService.getStyleList(this.$route.params.versionUuid)
            return response.data;
        },
        async prepareTemplateData() {
            let response = await ConfiguratorStylesService.getTemplateList(this.$route.params.versionUuid)
            return response.data[0].form;
        },
        async editData(style) {
            this.$vs.loading()
            style.form.template.forEach(element => {
                if(style.form.values[element.key] === "") {
                    delete style.form.values[element.key];
                }
            });

            try {
                 await ConfiguratorStylesService.editValuesList(style);
                this.$vs.notify({ text: this.$t("apps.configurator.menu-list.updated-items.ok"), color: 'success', position: 'top-right' })
            } catch (error) {
                this.$vs.notify({ text: this.$t("apps.configurator.menu-list.updated-items.ko"), color: 'danger', position: 'top-right' })
            } finally {
                this.$vs.loading.close();
            }
        },
        missingStyles() {
            return this.styles.length < 2
        },
        createStyle() {
            this.createNewStyle = true;
        },
        async addStyle(style) {
            this.$vs.loading()
            if(style.timeOfYear === '') style.timeOfYear = this.timeOfYear;
            style.uuid = uuidv4();
            style.form.template.forEach(element => {
                if(style.form.values[element.key] === "") {
                    delete style.form.values[element.key];
                }
            });

            try {
                await ConfiguratorStylesService.addNewStyle(style, this.$route.params.versionUuid);
                this.$vs.notify({ text: this.$t("apps.configurator.menu-list.updated-items.ok"), color: 'success', position: 'top-right' })
                this.createNewStyle = false;
                await this.initData()
            } catch (error) {
                this.$vs.notify({ text: this.$t("apps.configurator.menu-list.updated-items.ko"), color: 'danger', position: 'top-right' })
            } finally {
                this.$vs.loading.close();
            }
        },
        async resetData(style) {
            await this.load(async () => {
                let responseValues = await ConfiguratorStylesService.getValuesList(style.uuid);
                const styleIndex = this.styles.findIndex((element) => {
                    return element.uuid = style.uuid
                })
                this.styles[styleIndex].form.values = responseValues.values
            })

        },
        showDeleteConfirm(e, style) {
            this.$vs.dialog({
                type: 'confirm',
                color: 'danger',
                title: this.$t("apps.configurator.styles.delete.title.confirmation"),
                text: this.$t("apps.configurator.styles.delete.text.confirmation"),
                accept: () => this.deleteStyle(style)
            })

        },
        async deleteStyle(style) {
            await this.load(async () => {
                await ConfiguratorStylesService.deleteStyle(style);
            })

            const styleIndex = this.styles.findIndex((element) => {
                return element.uuid = style.uuid
            })
            this.styles.splice(styleIndex, 1);
        },
        removeNewStyle() {
            this.createNewStyle = false
        }
    }
}
</script>

<style>
.vs-collapse {
    @apply bg-background-grey;
}

.vs-collapse-item--content {
    @apply bg-white;
}

.vs-collapse {
    padding:0px !important;
}
.vs-collapse-item--header{
    padding: 10px !important;
}
</style>
