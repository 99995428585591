<template>
  <div>
    <div class="vx-row mt-3">
      <div class="vx-col w-full mb-10 text-right space-x-4">
        <slot name="actionButtons"></slot>
      </div>
    </div>
    <div>
      <slot name="datatable"></slot>
    </div>
  </div>
</template>

<script>

export default {
  name: 'datatablePageLayout',
  props: { }
}

</script>
