var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("DatatablePageLayout", {
    scopedSlots: _vm._u([
      {
        key: "actionButtons",
        fn: function() {
          return [
            _vm.missingStyles() && !_vm.isReadOnly
              ? _c(
                  "vs-button",
                  {
                    staticClass: "rounded-lg xs:w-full sm:w-auto",
                    on: {
                      click: function($event) {
                        return _vm.createStyle()
                      }
                    }
                  },
                  [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("apps.configurator.styles.new-style-button")
                        ) +
                        " "
                    )
                  ]
                )
              : _vm._e()
          ]
        },
        proxy: true
      },
      {
        key: "datatable",
        fn: function() {
          return [
            _vm.styles.length === 0 && !_vm.createNewStyle
              ? _c(
                  "div",
                  { staticClass: "w-full vx-col mt-5" },
                  [
                    _c("emptyAlert", {
                      attrs: {
                        message: _vm.$t(
                          "apps.configurator.alert-empty.styles-text"
                        )
                      }
                    })
                  ],
                  1
                )
              : _vm._e(),
            _vm.styles.length > 0 || _vm.createNewStyle
              ? _c(
                  "div",
                  { staticClass: "w-full vx-col bg-white p-8" },
                  [
                    _vm.styles.length > 0 || _vm.createNewStyle
                      ? _c(
                          "vs-collapse",
                          [
                            _vm.createNewStyle
                              ? _c(
                                  "vs-collapse-item",
                                  { key: "new", attrs: { open: "" } },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "flex justify-between items-center",
                                        attrs: { slot: "header" },
                                        slot: "header"
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pl-5 text-lg font-medium"
                                          },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "apps.configurator.styles.new-style"
                                                )
                                              )
                                            )
                                          ]
                                        ),
                                        !_vm.isReadOnly
                                          ? _c(
                                              "vs-button",
                                              {
                                                staticClass:
                                                  "rounded-lg px-3 py-2 mr-5 text-danger",
                                                attrs: {
                                                  type: "border",
                                                  color: "danger"
                                                },
                                                on: {
                                                  click: _vm.removeNewStyle
                                                }
                                              },
                                              [
                                                _c("feather-icon", {
                                                  attrs: {
                                                    icon: "TrashIcon",
                                                    svgClasses:
                                                      "align-middle w-6"
                                                  }
                                                })
                                              ],
                                              1
                                            )
                                          : _vm._e()
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "px-8" },
                                      [
                                        _c(
                                          "label-form",
                                          { attrs: { name: "Select_mode" } },
                                          [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "apps.configurator.styles.select-mode"
                                                )
                                              ) + " *"
                                            )
                                          ]
                                        ),
                                        _c(
                                          "vs-select",
                                          {
                                            staticClass: "w-full md:w-1/3",
                                            on: { change: _vm.setTimeOfYear },
                                            model: {
                                              value: _vm.timeOfYear,
                                              callback: function($$v) {
                                                _vm.timeOfYear = $$v
                                              },
                                              expression: "timeOfYear"
                                            }
                                          },
                                          _vm._l(_vm.timeOfYearOps, function(
                                            time
                                          ) {
                                            return _c("vs-select-item", {
                                              key: time.value,
                                              attrs: {
                                                value: time.value,
                                                text: time.label
                                              }
                                            })
                                          }),
                                          1
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "mt-5" },
                                          [
                                            _c("validaton-dynamic-form", {
                                              attrs: {
                                                isReadOnly: _vm.isReadOnly,
                                                form: _vm.newStyle
                                              },
                                              on: {
                                                "update-data": _vm.addStyle
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                )
                              : _vm._e(),
                            _vm._l(_vm.styles, function(style) {
                              return _c(
                                "vs-collapse-item",
                                { key: style.timeOfYear },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "flex justify-between items-center",
                                      attrs: { slot: "header" },
                                      slot: "header"
                                    },
                                    [
                                      _c(
                                        "span",
                                        {
                                          staticClass:
                                            "pl-5 text-lg font-medium"
                                        },
                                        [_vm._v(_vm._s(style.timeOfYear))]
                                      ),
                                      !_vm.isReadOnly
                                        ? _c(
                                            "vs-button",
                                            {
                                              staticClass:
                                                "rounded-lg px-3 py-2 mr-5 text-danger",
                                              attrs: {
                                                type: "border",
                                                color: "danger"
                                              },
                                              on: {
                                                click: function(e) {
                                                  return _vm.showDeleteConfirm(
                                                    e,
                                                    style
                                                  )
                                                }
                                              }
                                            },
                                            [
                                              _c("feather-icon", {
                                                attrs: {
                                                  icon: "TrashIcon",
                                                  svgClasses: "align-middle w-6"
                                                }
                                              })
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "px-8" },
                                    [
                                      _c("validaton-dynamic-form", {
                                        attrs: {
                                          isReadOnly: _vm.isReadOnly,
                                          form: style
                                        },
                                        on: {
                                          "update-data": _vm.editData,
                                          "reset-data": function($event) {
                                            return _vm.resetData(style)
                                          }
                                        }
                                      })
                                    ],
                                    1
                                  )
                                ]
                              )
                            })
                          ],
                          2
                        )
                      : _vm._e()
                  ],
                  1
                )
              : _vm._e()
          ]
        },
        proxy: true
      }
    ])
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }