<template>
    <div>
        <dynamic-form :isReadOnly="isReadOnly" v-model="form.form" @check-validation="checkValidation"/>
        <div class="py-8" v-if="!isReadOnly">
            <vs-button type="border" class="rounded-lg mr-3" @click="$emit('reset-data')">{{ $t("apps.configurator.home-layout.cancel") }}</vs-button>   
            <vs-button @click="updateData" :disabled="invalidForm">{{ $t("apps.configurator.home-layout.save") }}</vs-button>   
        </div>
    </div>
</template>

<script>
import DynamicForm from '../../../Shared/Components/form/DynamicForm.vue'

export default {
    components: { 
        DynamicForm,
    },
    props: {
        form: Object,
        isReadOnly: { type: Boolean, default: false },
    },
    data() {
        return {
            invalidForm: false,
        }
    },
    methods: {
        async updateData() {
            this.$nextTick(() => {
                this.$emit('update-data', this.form);
            });
        },
        checkValidation(validation) {
            this.invalidForm = !validation;
        },
    },
}
</script>