var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "vx-row mt-3" }, [
      _c(
        "div",
        { staticClass: "vx-col w-full mb-10 text-right space-x-4" },
        [_vm._t("actionButtons")],
        2
      )
    ]),
    _c("div", [_vm._t("datatable")], 2)
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }