import notifications from "@/modules/Shared/Mixins/notifications.js";

const loader = {
	mixins: [notifications],
	methods: {
		async load(func) {
			this.$vs.loading();
			await func()
			this.$vs.loading.close();
		},

		async loadAndNotify(action, onlyError = false) {
			try {
				await this.load(action)
				if (!onlyError) {
					this.notifySuccess()
				}
			} catch (e) {
				this.$vs.loading.close();
				this.notifyError()
			}
		},

		async loadAndNotifyError(action) {
			this.loadAndNotify(action, true)
		}
	}
}

export default loader
